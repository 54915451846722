import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import './About.css'
import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import { Link } from 'react-router-dom'
import { List } from 'antd'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'

const sideList = [
    // {
    //     title: 'About Us',
    //     link: '/about'
    // },
    {
        title: 'Partner Brands',
        link: '/partner-brands'
    },
    {
        title: 'Contact Us',
        link: '/contact'
    },
]

export default function About() {
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // // find about and mission content
    // const aboutContent = contents?.find(
    //     (content) => content.code === ContentCode.ABOUT
    // )
    const isMobile = useSelector((state) => state.content.isMobile)

    const { isPending, error, data: aboutContent } = ContentDataHook(ContentCode.ABOUT)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'About Page: An error has occurred: ' + error.message

    return (
        <>
            <SeoTitleAndDesc
                title={aboutContent?.title || 'About Us'}
                seo_title={aboutContent?.seo_title}
                seo_desc={aboutContent?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title=''
                background='/images/banner_one_stop_shop.webp'
                height={isMobile ? '120px' : '400px'}
                extraClass={{
                    backgroundSize: 'contain'
                }}
            />
            <Navigation
                title='About Us'
                titleLink='/about'
            />
            {aboutContent &&
                <div className='about-wrapper'>
                    <div className='about-left'>
                        {/* <div className='about-left-item'>
                            <h3>Need help?</h3>
                            <p>1-800-555-5555</p>
                            <p>info@tiremaxx.ca</p>
                            <p>MON-FRI: 9:30a.m.-5p.m.</p>
                            <p>SAT: 10a.m.-3:30p.m.</p>
                            <p>SUN: Closed</p>
                        </div> */}
                        <List
                            header={<h3>Quick Links</h3>}
                            bordered
                            size='large'
                            dataSource={sideList}
                            renderItem={(item) => <List.Item><Link to={item.link}>{item.title}</Link></List.Item>}
                        />
                    </div>
                    <div className='about-content'>
                        <h3>{aboutContent?.title}</h3>
                        <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: aboutContent?.content }}></p>
                    </div>
                </div>
            }
        </>
    )
}
