import React, { useState } from 'react'
import ProductSide from './ProductSide'
import ProductItem from './ProductItem'
import ReactPaginate from 'react-paginate'
import { useSelector, useDispatch } from 'react-redux'
// import { getProductContent } from '@services/api.service'
import { setFilterPage, setGotoElement, setCurrentProduct } from '@reducers/contentReducer'
import helper from '@utils/helper'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
// import { useMediaQuery } from 'react-responsive'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import Navigation from '@components/Navigation'

export default function ProductList({
    user,
    pageTitle,
    seoData
}) {
    const dispatch = useDispatch()
    const productsList = useSelector((state) => state.content.productsList)
    const productsFilter = useSelector((state) => state.content.productsFilter)
    const handlePageClick = (e) => {
        const selected = e.selected
        dispatch(setFilterPage(selected + 1))
        dispatch(setGotoElement('product-list'))
    }
    const isMobile = useSelector((state) => state.content.isMobile)
    // const isMobile = useMediaQuery({ query: '(max-width: 630px' })
    const [isShowSide, setIsShowSide] = useState(true)

    const handleClickProduct = async (id) => {
        // try {
        //     const result = await getProductContent(id, { username: user?.username || '', token: user?.token || '' })
        //     console.log('result', result?.data)
        //     dispatch(setCurrentProduct(result?.data))
        // } catch (err) {
        //     console.log(err.data?.message || 'get product content error')
        // }
    }

    console.log('productsList seoData:', seoData)

    return (
        <>
            <Navigation
                title='Tires'
                titleLink='/tires'
                subtitle={seoData?.page ? seoData?.page : (seoData?.h1 || 'Tires')}
                subtitleLink={'#'}
            />
            <SeoTitleAndDesc
                title={pageTitle}
                seo_title={seoData?.title}
                seo_desc={seoData?.desc}
            />
            <div className='mobile-filter' onClick={() => {
                setIsShowSide(!isShowSide)
            }}>
                <h3>FILTER</h3>
                {isShowSide ?
                    <IoIosArrowUp size={20} />
                    : <IoIosArrowDown size={20} />}
            </div>
            <div className="product-content">
                {isShowSide &&
                    <ProductSide />
                }
                <div className="product-list" id='product-list'>
                    <div className="column-header">
                        <h1>{seoData?.page ? seoData?.page : (seoData?.h1 || 'Tires')}</h1>
                        <span className="header-total">Total {productsList?.total} products found</span>
                    </div>
                    <div className="product-grid">
                        {productsList.data && productsList.data.map((item, index) => (
                            <ProductItem
                                key={index}
                                product={item}
                                link={`/tires/detail/${item.id}/${helper.formatTitleForLink(item.title)}`}
                                onClick={() => handleClickProduct(item.id)}
                            />
                        ))}
                        {productsList.total === 0 && <div className="no-data">No product information was found.</div>}
                    </div>
                    {productsList.totalPage > 0 && (
                        <div className="pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={4}
                                pageCount={productsList.totalPage}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                forcePage={productsFilter.current - 1}
                            // onClick={handleGotoElement}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
