import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import ServiceSection from '@components/ServiceSection'
// import { useSelector } from 'react-redux'
import { ContentCode } from '@data/ContentData'
import { Link, useParams } from 'react-router-dom'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'
import './Service.css'
import ServiceDetails from '@data/ServiceDetailsData'

export default function ServiceNew({
    handleSetFilter
}) {
    const { id } = useParams()
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)
    // find about and mission content
    let isFind = false
    let code = ContentCode.SERVICES
    let subtitle = ''
    if (id === 'shipping') {
        code = ContentCode.SHIPPING
        subtitle = 'Shipping'
        isFind = true
    } else if (id === 'shipping-area') {
        code = ContentCode.SHIPPING_AREA
        isFind = true
        subtitle = 'Shipping Area'
    }
    // const content = contents?.find(
    //     (content) => content.code === code
    // )

    if (['tire-installation', 'rims-and-wheels', 'financing', 'local-shipping'].includes(id)) {
        // content = contents?.find(
        //     (content) => content.code === code
        // )
        code = ContentCode.SERVICES
        isFind = true
        switch (id) {
            case 'tire-installation':
                subtitle = 'Tire Installation'
                break
            case 'rims-and-wheels':
                subtitle = 'Rims and Wheels'
                break
            case 'financing':
                subtitle = 'Financing'
                break
            case 'local-shipping':
                subtitle = 'Local Shipping'
                break
            default:
                subtitle = ''
                break
        }
    }
    if (id) {
        const service = ServiceDetails.find(item => item.code === id)
        if (service) {
            console.log('service', service)
            code = service.code
            isFind = true
            subtitle = service.title
        }
    }
    let { isPending, error, data: content } = ContentDataHook(code)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Service Page: An error has occurred: ' + error.message
    //tire-installation, rims-and-wheels, financing, local-shipping
    if (!isFind)
        content = ''

    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Services'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Services'
                background='/images/banner-services.webp'
            // description='Tiremaxx'
            />
            <Navigation
                title='Services'
                titleLink='/services'
                subtitle={subtitle}
            />
            {content ?
                <ServiceSection
                    title={content?.title}
                    // subtitle={content.title}
                    description={content.content}
                    productTypeList={[]}
                    handleSetFilter={handleSetFilter}
                />
                :
                <div className='service-wrap'>
                    <div className='service-content'>
                        <div className='service-header'>
                            <h1>TIRE SERVICES</h1>
                            <p>At Tiremaxx, our services go beyond just selling tires—we are dedicated to ensuring your vehicle's optimal performance and safety. Our expert technicians offer professional tire installation, precise balancing, accurate alignment, and reliable repair services. Trust Tiremaxx for all your tire solutions, where quality meets affordability, and customer satisfaction is our top priority.</p>
                        </div>
                        <div className='service-list'>
                            {ServiceDetails.map((service, index) => (
                                <Link to={`/services/${service.code}`} className='service-card' key={index}>
                                    <img src={service.image} width={100} alt={service.title} />
                                    <h3>{service.title}</h3>
                                    <p>{service.desc}</p>
                                    <Link to={`/services/${service.code}`}>Read more {'>'}</Link>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
