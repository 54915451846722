const ProductSeoData = [
    {
        id: 'summertires',
        title: 'Summer Tires Sale — Buy Cheap, High-Performance Tires in Calgary | Tremaxx',
        desc: 'Discover the best summer tires at Tiremaxx. Get great deals and discounts on high performance summer tyres. Shop now and save with free shipping!',
        h1: 'Find the best summer tires at affordable prices buy high performance summer tires today',
        page: 'Summer Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/summertires'
    },
    {
        id: 'wintertires',
        title: 'Winter Tires Sale — Buy Discounted, Quality Tyres in Calgary | Tremaxx',
        desc: 'Find the best winter tires at Tiremaxx. Enjoy great discounts on high-quality winter tyres. Shop now and benefit from our money-back guarantee!',
        h1: 'Get high quality winter tires with discounts buy new winter tires at great prices',
        page: 'Winter Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/wintertires'
    },
    {
        id: 'allseasontires',
        title: 'All-Season Tires Sale — Buy Cheap, Durable Tyres in Calgary | Tremaxx',
        desc: 'Shop the best all season tires at Tiremaxx. Get great deals and discounts on durable all season tyres. Save today with our special offers!',
        h1: 'Shop reliable all season tires buy durable tires with great deals',
        page: 'All Season Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/allseasontires'
    },
    {
        id: 'mudterraintires',
        title: 'Mud Terrain Tires Sale — Buy Discounted, High-Performance Tyres in Calgary | Tremaxx',
        desc: 'Explore the best mud terrain tires at Tiremaxx. Get discounts on high performance mud tyres. Shop now and enjoy our free shipping offer!',
        h1: 'Explore high performance mud terrain tires at reduced prices buy the best off road tires',
        page: 'Mud Terrain Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/mudterraintires'
    },
    {
        id: 'allterraintires',
        title: 'All-Terrain Tires Sale — Buy Cheap, High-Performance Tyres in Calgary | Tremaxx',
        desc: 'Find the best all terrain tires at Tiremaxx. Enjoy great deals and discounts on high performance all terrain tyres. Save today with our special offers!',
        h1: 'Discover the best all terrain tires buy high performance all terrain tires today',
        page: 'All Terrain Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/allterraintires'
    },
    {
        id: 'allweathertires',
        title: 'All-Weather Tires Sale — Buy Discounted, Durable Tyres in Calgary | Tremaxx',
        desc: 'Discover the best all weather tires at Tiremaxx. Get great deals and discounts on durable all weather tyres. Shop now and benefit from our money-back guarantee!',
        h1: 'Get durable all weather tires at discounted prices buy high quality all season tires',
        page: 'All Weather Tires for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/allweathertires'
    },
    {
        id: 'tirebags',
        title: 'Tire Bags Sale — Buy Cheap, Durable Tyre Storage Bags in Calgary | Tremaxx',
        desc: 'Shop the best tire bags at Tiremaxx. Get great deals and discounts on durable tyre storage bags. Save today with our special offers!',
        h1: 'Shop sturdy tire storage bags buy the best tire bags at affordable prices',
        page: 'Tire Bags for Sale in Calgary',
        link: 'https://www.tiremaxx.ca/tires/list/tirebags'
    }
]
export default ProductSeoData