import './Navigation.css';
import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillHome } from 'react-icons/ai'

export default function Navigation({
    title,
    titleLink,
    subtitle,
    subtitleLink,
}) {
    return (
        <div className="navigation">
            <ul>
                <li><AiFillHome /></li>
                <li><Link to="/">Home</Link></li>
                <li>/</li>
                <li><Link to={titleLink}>{title}</Link></li>
                {subtitle && <>
                    <li>/</li>
                    <li><Link to={subtitleLink}>{subtitle}</Link></li>
                </>
                }
            </ul>
        </div>
    )
}
