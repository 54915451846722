import React from 'react'
import BannerSection from '@components/BannerSection'
import Navigation from '@components/Navigation'
import { useParams, Link } from 'react-router-dom'
import './Policy.css'
// import { useSelector } from 'react-redux'
import SeoTitleAndDesc from '@components/SeoTitleAndDesc'
import ContentDataHook from '@hooks/ContentData.hook'

export default function PolicyDetail() {

    const { id } = useParams()
    // get contents from redux
    // const contents = useSelector((state) => state.content.contents)

    // find about and mission content
    // const content = contents?.find(
    //     (content) => content.code === id
    // )

    const { isPending, error, data: content } = ContentDataHook(id)
    if (isPending) return <div style={{ textAlign: 'center' }}>Loading...</div>
    if (error) return 'Policy Detail Page: An error has occurred: ' + error.message

    return (
        <>
            <SeoTitleAndDesc
                title={content?.title || 'Policy'}
                seo_title={content?.seo_title}
                seo_desc={content?.seo_desc}
            />
            <BannerSection
                color='#fff'
                title='Policies'
                background='/images/prolicy-banner.webp'
            />
            <Navigation
                title='Policies'
                titleLink='/policies'
                subtitle={content ? content.title : ''}
                subtitleLink={content ? `/policy/${content.code}` : ''}
            />
            {content ? (
                <div className="policy-detail">
                    <div className="policy-side">
                        {id !== 'refund' && (
                            <Link to="/policy/refund">
                                <div className="policy-item policy-item-refund" >
                                    <h4 className="policy-title">Refund Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Refund Policy</h4>
                                        <p>We have a 7-day return policy, which means you have 7 days after receiving your item to request a return.</p>
                                    </div>
                                </div>
                            </Link>
                        )}
                        {id !== 'store' && (
                            <Link to="/policy/store">
                                <div className="policy-item policy-item-store">
                                    <h4 className="policy-title">Warranty Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Warranty Policy</h4>
                                        <p>All Tires are warranted against defects in workmanship and material and will be replaced 100% free of charge for the initial warranty period...</p>
                                    </div>
                                </div>
                            </Link>
                        )}
                        {id !== 'privacy' && (
                            <Link to="/policy/privacy">
                                <div className="policy-item policy-item-privacy">
                                    <h4 className="policy-title">Privacy Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Privacy Policy</h4>
                                        <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases.</p>
                                    </div>
                                </div>
                            </Link>
                        )}
                        {id !== 'pricing' && (
                            <Link to="/policy/pricing">
                                <div className="policy-item policy-item-pricing">
                                    <h4 className="policy-title">Exclusive Pricing Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Exclusive Pricing Policy</h4>
                                        <p>For auto-body shops that are registered with us, we have a loyalty program which gives our partners a chance to take advantage of exclusive partner pricing.</p>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                    <div className="policy-content">
                        <h3 style={{ textAlign: 'left' }}>{content.title}</h3>
                        <p className='desc-content ql-editor' dangerouslySetInnerHTML={{ __html: content.content }}></p>
                    </div>
                </div>
            ) : (
                <div className="policy-section">
                    <div className="policy-content">
                        <h3>Policies<span>&nbsp;</span></h3>
                        <div className="policy-list">
                            <Link to="/policy/refund">
                                <div className="policy-item policy-item-refund">
                                    <h4 className="policy-title">Refund Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Refund Policy</h4>
                                        <p>We have a 7-day return policy, which means you have 7 days after receiving your item to request a return.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/policy/store">
                                <div className="policy-item policy-item-store">
                                    <h4 className="policy-title">Warranty Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Warranty Policy</h4>
                                        <p>All Tires are warranted against defects in workmanship and material and will be replaced 100% free of charge for the initial warranty period...</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="policy-list">
                            <Link to="/policy/privacy">
                                <div className="policy-item policy-item-privacy">
                                    <h4 className="policy-title">Privacy Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Privacy Policy</h4>
                                        <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/policy/pricing">
                                <div className="policy-item policy-item-pricing">
                                    <h4 className="policy-title">Exclusive Pricing Policy</h4>
                                    <div className="policy-desc">
                                        <h4>Exclusive Pricing Policy</h4>
                                        <p>For auto-body shops that are registered with us, we have a loyalty program which gives our partners a chance to take advantage of exclusive partner pricing.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
